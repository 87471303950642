var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-card", { staticClass: "liveinfo-form", attrs: { "no-body": "" } }, [
        _c(
          "div",
          { staticClass: "m-2" },
          [
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-end",
                    attrs: { cols: "12", md: "12" },
                  },
                  [
                    _c("v-date-picker", {
                      staticClass: "el-def",
                      staticStyle: { width: "150px" },
                      attrs: { type: "month", "value-type": "YYYYMM" },
                      model: {
                        value: _vm.query.searchDate,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "searchDate", $$v)
                        },
                        expression: "query.searchDate",
                      },
                    }),
                    _c("label", { staticClass: "mr-1" }, [
                      _vm._v("(*당일데이터는 포함되지 않음)"),
                    ]),
                    _c(
                      "b-button",
                      {
                        staticClass: "btn-search",
                        attrs: { variant: "primary" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.fetchData()
                          },
                        },
                      },
                      [_vm._v(" 조회 ")]
                    ),
                    _c(
                      "b-button",
                      {
                        staticClass: "btn-search ml-1",
                        attrs: { variant: "outline-dark" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.excelDownload()
                          },
                        },
                      },
                      [
                        _c("feather-icon", {
                          staticClass: "mr-50",
                          attrs: { icon: "DownloadIcon" },
                        }),
                        _c("span", [_vm._v("엑셀 다운로드")]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "b-card",
        [
          _c(
            "b-table-simple",
            {
              staticStyle: { "max-height": "65vh" },
              attrs: { bordered: "", responsive: "", "sticky-header": "" },
            },
            [
              _c("colgroup", [
                _c("col", { staticStyle: { width: "7%" } }),
                _c("col"),
                _c("col", { staticStyle: { width: "7%" } }),
                _c("col", { staticStyle: { width: "18%" } }),
                _c("col", { staticStyle: { width: "12%" } }),
                _c("col", { staticStyle: { width: "12%" } }),
                _c("col", { staticStyle: { width: "12%" } }),
                _c("col", { staticStyle: { width: "12%" } }),
              ]),
              _c(
                "b-thead",
                { attrs: { "head-variant": "light" } },
                [
                  _c(
                    "b-tr",
                    [
                      _c("b-th", [_vm._v("ID")]),
                      _c("b-th", [_vm._v("이벤트 제목")]),
                      _c("b-th", [_vm._v("회원 구분")]),
                      _c("b-th", [_vm._v("진료과")]),
                      _c("b-th", [_vm._v("클릭 수")]),
                      _c("b-th", [_vm._v("참여 완료 수")]),
                      _c("b-th", [_vm._v("보상 지급 건수")]),
                      _c("b-th", [_vm._v("결과")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.eventData.length > 0
                ? _c(
                    "b-tbody",
                    [
                      _vm._l(_vm.eventData, function (item) {
                        return [
                          _vm._l(
                            item.memberStatistics,
                            function (member, index) {
                              return [
                                _c(
                                  "b-tr",
                                  { key: "member-" + item.id + "-" + index },
                                  [
                                    index === 0
                                      ? _c(
                                          "b-td",
                                          {
                                            attrs: {
                                              rowspan:
                                                (item.memberStatistics.length >
                                                0
                                                  ? item.memberStatistics
                                                      .length + 1
                                                  : 0) +
                                                (item.noneMemberStatistics
                                                  .length > 0
                                                  ? item.noneMemberStatistics
                                                      .length + 1
                                                  : 0) +
                                                1,
                                            },
                                          },
                                          [_vm._v(" " + _vm._s(item.id) + " ")]
                                        )
                                      : _vm._e(),
                                    index === 0
                                      ? _c(
                                          "b-td",
                                          {
                                            attrs: {
                                              rowspan:
                                                (item.memberStatistics.length >
                                                0
                                                  ? item.memberStatistics
                                                      .length + 1
                                                  : 0) +
                                                (item.noneMemberStatistics
                                                  .length > 0
                                                  ? item.noneMemberStatistics
                                                      .length + 1
                                                  : 0) +
                                                1,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.title) + " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    index === 0
                                      ? _c(
                                          "b-td",
                                          {
                                            attrs: {
                                              rowspan:
                                                item.memberStatistics.length,
                                            },
                                          },
                                          [_vm._v(" 회원 ")]
                                        )
                                      : _vm._e(),
                                    _c("b-td", [
                                      _vm._v(_vm._s(member.medicalDept)),
                                    ]),
                                    _c("b-td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("$numberFormatter")(
                                            member.clickCount
                                          )
                                        )
                                      ),
                                    ]),
                                    _c("b-td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("$numberFormatter")(
                                            member.joinCount
                                          )
                                        )
                                      ),
                                    ]),
                                    _c("b-td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("$numberFormatter")(
                                            member.rewardCount
                                          )
                                        )
                                      ),
                                    ]),
                                    index === 0
                                      ? _c(
                                          "b-td",
                                          {
                                            attrs: {
                                              rowspan:
                                                (item.memberStatistics.length >
                                                0
                                                  ? item.memberStatistics
                                                      .length + 1
                                                  : 0) +
                                                (item.noneMemberStatistics
                                                  .length > 0
                                                  ? item.noneMemberStatistics
                                                      .length + 1
                                                  : 0) +
                                                1,
                                            },
                                          },
                                          [
                                            item.eventTypeId == 7
                                              ? _c("div", [
                                                  _vm.userData.isPrivateInternal
                                                    ? _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "underline",
                                                          attrs: { href: "#" },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              return _vm.privacyFileDownload(
                                                                item.id
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("다운로드")]
                                                      )
                                                    : _c("p", [
                                                        _vm._v(" 내부망 지원 "),
                                                      ]),
                                                ])
                                              : _c("div", [_vm._v(" 미지원 ")]),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            }
                          ),
                          item.memberStatistics.length > 0
                            ? _c(
                                "b-tr",
                                { key: "member-total-" + item.id },
                                [
                                  _c(
                                    "b-td",
                                    {
                                      staticStyle: { "text-align": "center" },
                                      attrs: { colspan: "2" },
                                    },
                                    [_vm._v(" 소계 ")]
                                  ),
                                  _c("b-td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("$numberFormatter")(
                                          item.memberStatistics.reduce(
                                            function (f, l) {
                                              return f + l.clickCount
                                            },
                                            0
                                          )
                                        )
                                      )
                                    ),
                                  ]),
                                  _c("b-td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("$numberFormatter")(
                                          item.memberStatistics.reduce(
                                            function (f, l) {
                                              return f + l.joinCount
                                            },
                                            0
                                          )
                                        )
                                      )
                                    ),
                                  ]),
                                  _c("b-td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("$numberFormatter")(
                                          item.memberStatistics.reduce(
                                            function (f, l) {
                                              return f + l.rewardCount
                                            },
                                            0
                                          )
                                        )
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._l(
                            item.noneMemberStatistics,
                            function (member, index) {
                              return [
                                _c(
                                  "b-tr",
                                  {
                                    key: "nonemember-" + item.id + "-" + index,
                                  },
                                  [
                                    index === 0 &&
                                    item.memberStatistics.length === 0
                                      ? _c(
                                          "b-td",
                                          {
                                            attrs: {
                                              rowspan:
                                                (item.memberStatistics.length >
                                                0
                                                  ? item.memberStatistics
                                                      .length + 1
                                                  : 0) +
                                                (item.noneMemberStatistics
                                                  .length > 0
                                                  ? item.noneMemberStatistics
                                                      .length + 1
                                                  : 0) +
                                                1,
                                            },
                                          },
                                          [_vm._v(" " + _vm._s(item.id) + " ")]
                                        )
                                      : _vm._e(),
                                    index === 0 &&
                                    item.memberStatistics.length === 0
                                      ? _c(
                                          "b-td",
                                          {
                                            attrs: {
                                              rowspan:
                                                (item.memberStatistics.length >
                                                0
                                                  ? item.memberStatistics
                                                      .length + 1
                                                  : 0) +
                                                (item.noneMemberStatistics
                                                  .length > 0
                                                  ? item.noneMemberStatistics
                                                      .length + 1
                                                  : 0) +
                                                1,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.title) + " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    index === 0
                                      ? _c(
                                          "b-td",
                                          {
                                            attrs: {
                                              rowspan:
                                                item.noneMemberStatistics
                                                  .length,
                                            },
                                          },
                                          [_vm._v(" 비회원 ")]
                                        )
                                      : _vm._e(),
                                    _c("b-td", [
                                      _vm._v(_vm._s(member.medicalDept)),
                                    ]),
                                    _c("b-td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("$numberFormatter")(
                                            member.clickCount
                                          )
                                        )
                                      ),
                                    ]),
                                    _c("b-td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("$numberFormatter")(
                                            member.joinCount
                                          )
                                        )
                                      ),
                                    ]),
                                    _c("b-td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("$numberFormatter")(
                                            member.rewardCount
                                          )
                                        )
                                      ),
                                    ]),
                                    index === 0 &&
                                    item.memberStatistics.length === 0
                                      ? _c(
                                          "b-td",
                                          {
                                            attrs: {
                                              rowspan:
                                                (item.memberStatistics.length >
                                                0
                                                  ? item.memberStatistics
                                                      .length + 1
                                                  : 0) +
                                                (item.noneMemberStatistics
                                                  .length > 0
                                                  ? item.noneMemberStatistics
                                                      .length + 1
                                                  : 0) +
                                                1,
                                            },
                                          },
                                          [
                                            item.eventTypeId == 7
                                              ? _c("div", [
                                                  _vm.userData.isPrivateInternal
                                                    ? _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "underline",
                                                          attrs: { href: "#" },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              return _vm.privacyFileDownload(
                                                                item.id
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("다운로드")]
                                                      )
                                                    : _c("p", [
                                                        _vm._v(" 내부망 지원 "),
                                                      ]),
                                                ])
                                              : _c("div", [_vm._v(" 미지원 ")]),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            }
                          ),
                          item.noneMemberStatistics.length > 0
                            ? _c(
                                "b-tr",
                                { key: "nonemember-total-" + item.id },
                                [
                                  _c(
                                    "b-td",
                                    {
                                      staticStyle: { "text-align": "center" },
                                      attrs: { colspan: "2" },
                                    },
                                    [_vm._v(" 소계 ")]
                                  ),
                                  _c("b-td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("$numberFormatter")(
                                          item.noneMemberStatistics.reduce(
                                            function (f, l) {
                                              return f + l.clickCount
                                            },
                                            0
                                          )
                                        )
                                      )
                                    ),
                                  ]),
                                  _c("b-td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("$numberFormatter")(
                                          item.noneMemberStatistics.reduce(
                                            function (f, l) {
                                              return f + l.joinCount
                                            },
                                            0
                                          )
                                        )
                                      )
                                    ),
                                  ]),
                                  _c("b-td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("$numberFormatter")(
                                          item.noneMemberStatistics.reduce(
                                            function (f, l) {
                                              return f + l.rewardCount
                                            },
                                            0
                                          )
                                        )
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "b-tr",
                            { key: "total-" + item.id },
                            [
                              _c(
                                "b-td",
                                {
                                  staticStyle: { "text-align": "center" },
                                  attrs: { colspan: "2" },
                                },
                                [_vm._v(" 합계 ")]
                              ),
                              _c("b-td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("$numberFormatter")(
                                      item.memberStatistics.reduce(function (
                                        f,
                                        l
                                      ) {
                                        return f + l.clickCount
                                      },
                                      0) +
                                        item.noneMemberStatistics.reduce(
                                          function (f, l) {
                                            return f + l.clickCount
                                          },
                                          0
                                        )
                                    )
                                  )
                                ),
                              ]),
                              _c("b-td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("$numberFormatter")(
                                      item.memberStatistics.reduce(function (
                                        f,
                                        l
                                      ) {
                                        return f + l.joinCount
                                      },
                                      0) +
                                        item.noneMemberStatistics.reduce(
                                          function (f, l) {
                                            return f + l.joinCount
                                          },
                                          0
                                        )
                                    )
                                  )
                                ),
                              ]),
                              _c("b-td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("$numberFormatter")(
                                      item.memberStatistics.reduce(function (
                                        f,
                                        l
                                      ) {
                                        return f + l.rewardCount
                                      },
                                      0) +
                                        item.noneMemberStatistics.reduce(
                                          function (f, l) {
                                            return f + l.rewardCount
                                          },
                                          0
                                        )
                                    )
                                  )
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]
                      }),
                    ],
                    2
                  )
                : _c(
                    "b-tbody",
                    [
                      _c(
                        "b-tr",
                        [
                          _c("b-td", { attrs: { colspan: "8" } }, [
                            _c(
                              "div",
                              {
                                attrs: { role: "alert", "aria-live": "polite" },
                              },
                              [
                                _c("div", { staticClass: "text-center my-2" }, [
                                  _vm._v(" 일치하는 데이터가 없습니다. "),
                                ]),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      ),
      _c("editor-popup", {
        attrs: {
          "show-editor": _vm.showEditor,
          text: _vm.editorText,
          title: _vm.editorTitle,
          description: _vm.editorDesc,
        },
        on: { close: _vm.editorConfig.close },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }