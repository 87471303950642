import { render, staticRenderFns } from "./VisitAskView.vue?vue&type=template&id=bb7cbeda&"
import script from "./VisitAskView.vue?vue&type=script&lang=js&"
export * from "./VisitAskView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Jenkins_Home\\workspace\\liveinfo3-front-admin-dev\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('bb7cbeda')) {
      api.createRecord('bb7cbeda', component.options)
    } else {
      api.reload('bb7cbeda', component.options)
    }
    module.hot.accept("./VisitAskView.vue?vue&type=template&id=bb7cbeda&", function () {
      api.rerender('bb7cbeda', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/clients/statistics/durginfo-stast-view/VisitAskView.vue"
export default component.exports