export default [

  // *===============================================---*
  // *--------- 고객 관리 --- ---------------------------*
  // *===============================================---*

  // 고객 관리
  {
    path: '/apps/members/member-manage',
    name: 'apps-member-manage',
    component: () => import('@/views/apps/members/member-manage/MemberManage.vue'),
    meta: {
      roleLevel: 7,
    },
  },

  // 비 고객 관리
  {
    path: '/apps/members/non-member-manage',
    name: 'apps-non-member-manage',
    component: () => import('@/views/apps/members/non-member-manage/NonMemberManage.vue'),
    meta: {
      roleLevel: 7,
    },
  },

  // 1:1 문의 관리
  {
    path: '/apps/members/member-ask',
    name: 'apps-member-ask-history',
    component: () => import('@/views/apps/members/member-ask/MemberAskHistory.vue'),
    meta: {
      roleLevel: 7,
    },
  },

  // Call 문의 관리
  {
    path: '/apps/members/member-call',
    name: 'apps-member-call-history',
    component: () => import('@/views/apps/members/member-ask/MemberCallHistory.vue'),
    meta: {
      roleLevel: 7,
    },
  },

  // *===============================================---*
  // *--------- 웹세미나 ---- ----------------- -------*
  // *===============================================---*
  {
    path: '/app/contents/webseminar-manage',
    name: 'apps-webseminar-manage',
    component: () => import('@/views/apps/contents/webseminar-manage/WebSeminar.vue'),
    meta: {
      roleLevel: 7,
    },
  },

  // *===============================================---*
  // *--------- Survey ---- ----------------- ------------*
  // *===============================================---*

  // Survey 관리
  {
    path: '/app/contents/survey-manage',
    name: 'apps-survey-manage',
    component: () => import('@/views/apps/contents/survey-manage/SurveyManage.vue'),
    meta: {
      roleLevel: 7,
    },
  },

  // 설문지 목록
  {
    path: '/app/contents/survey-paper-list',
    name: 'apps-survey-paper-list',
    component: () => import('@/views/apps/contents/survey-paper-manage/SurveyPaperList.vue'),
    meta: {
      roleLevel: 7,
    },
  },

  // 설문지 관리(Editor)
  {
    path: '/app/contents/survey-paper-manage',
    name: 'apps-survey-paper-manage',
    component: () => import('@/views/apps/contents/survey-paper-manage/SurveyPaperManage.vue'),
    meta: {
      roleLevel: 7,
    },
    props: true,
  },

  // 설문조사 기프트 교환
  {
    path: '/app/contents/survey-gift-manage',
    name: 'apps-survey-gift-manage',
    component: () => import('@/views/apps/contents/survey-gift-manage/SurveyGiftManage.vue'),
    meta: {
      roleLevel: 7,
    },
  },

  // 설문조사 참여자 조회
  {
    path: '/app/contents/survey-answer-user',
    name: 'apps-survey-answer-user',
    component: () => import('@/views/apps/contents/survey-answer-user/SurveyAnswerUser.vue'),
    meta: {
      roleLevel: 7,
    },
  },

  // *===============================================---*
  // *--------- Comment Survey -------------------------------*
  // *===============================================---*

  // 투표형 댓글 관리
  {
    path: '/app/contents/comment-survey-manage',
    name: 'apps-comment-survey-manage',
    component: () => import('@/views/apps/contents/comment-survey-manage/CommentSurveyManage.vue'),
    meta: {
      roleLevel: 7,
    },
  },

  // *===============================================---*
  // *--------- 모아보기 배너관리 ---- ----------------- -------*
  // *===============================================---*
  {
    path: '/app/contents/moabanner-manage',
    name: 'apps-moabanner-manage',
    component: () => import('@/views/apps/contents/banner-manage/BannerManage.vue'),
    meta: {
      roleLevel: 7,
    },
  },

  // *===============================================---*
  // *--------- 삽입 배너관리 ---- ----------------- -------*
  // *===============================================---*
  {
    path: '/app/contents/insert-banner-manage',
    name: 'apps-insert-banner-manage',
    component: () => import('@/views/apps/contents/insert-banner-manage/InsertBannerManage.vue'),
    meta: {
      roleLevel: 7,
    },
  },

  // *===============================================---*
  // *--------- 라이브인포 ---- ----------------- -------*
  // *===============================================---*
  {
    path: '/app/contents/druginfo-manage',
    name: 'apps-druginfo-manage',
    component: () => import('@/views/apps/contents/druginfo-manage/DrugInfoManage.vue'),
    meta: {
      roleLevel: 7,
    },
  },

  // *===============================================---*
  // *--------- 라이브랜딩 (간편처방등록) --------------*
  // *===============================================---*
  {
    path: '/app/contents/druglanding-manage',
    name: 'apps-druglanding-manage',
    component: () => import('@/views/apps/contents/druglanding-manage/DrugLandingManage.vue'),
    meta: {
      roleLevel: 7,
    },
  },

  // *===============================================---*
  // *--------- 메디컬트렌드 ---- --------------- -------*
  // *===============================================---*
  {
    path: '/app/contents/medicaltrend-manage',
    name: 'apps-medicaltrend-manage',
    component: () => import('@/views/apps/contents/medicaltrend-manage/MedicalTrendManage.vue'),
    meta: {
      roleLevel: 7,
    },
  },

  // *===============================================---*
  // *--------- POLL ---- ----------------- ------------*
  // *===============================================---*

  // Poll 관리
  {
    path: '/app/poll/poll-manage',
    name: 'apps-poll-manage',
    component: () => import('@/views/apps/poll/poll-manage/PollManage.vue'),
    meta: {
      roleLevel: 7,
    },
  },

  // Poll 제안 관리
  {
    path: '/app/poll/poll-suggest',
    name: 'apps-poll-suggest',
    component: () => import('@/views/apps/poll/poll-suggest/PollSuggestManage.vue'),
    meta: {
      roleLevel: 7,
    },
  },

  // 제약사 등록
  {
    path: '/apps/clients/client-manage',
    name: 'apps-client-manage',
    component: () => import('@/views/apps/clients/client-manage/ClientManage.vue'),
    meta: {
      roleLevel: 7,
    },
  },

  // 공지사항
  {
    path: '/apps/others/notice-manage',
    name: 'apps-notice-manage',
    component: () => import('@/views/apps/others/notice-manage/NoticeManage.vue'),
    meta: {
      roleLevel: 7,
    },
  },

  // 이벤트
  {
    path: '/apps/others/event-manage',
    name: 'apps-event-manage',
    component: () => import('@/views/apps/others/event-manage/EventManage.vue'),
    meta: {
      roleLevel: 7,
    },
  },

  // 이벤트 기프트 교환
  {
    path: '/app/contents/event-gift-manage',
    name: 'apps-event-gift-manage',
    component: () => import('@/views/apps/contents/event-gift-manage/EventGiftManage.vue'),
    meta: {
      roleLevel: 7,
    },
  },

  // *===============================================---*
  // *--------- RESOURCE -------------------------------*
  // *===============================================---*

  // 이미지 리소스 관리
  {
    path: '/app/others/image-resource-manage',
    name: 'apps-image-resource-manage',
    component: () => import('@/views/apps/others/image-resource-manage/ImageResourceManage.vue'),
    meta: {
      roleLevel: 7,
    },
  },

  // *===============================================---*
  // *--------- 개발샘플 ---- ---------------------------*
  // *===============================================---*

  // 샘플 리스트 조회 화면
  {
    path: '/apps/examples/list',
    name: 'apps-examples-list',
    component: () => import('@/views/apps/examples/example-list/ExampleList.vue'),
    meta: {
      roleLevel: 9,
    },
  },

  // 샘플 관리 화면
  {
    path: '/apps/examples/manage',
    name: 'apps-examples-manage',
    component: () => import('@/views/apps/examples/example-manage/ExampleManage.vue'),
    meta: {
      roleLevel: 9,
    },
  },

  // *===============================================---*
  // *--------- 댓글관리 ---- ----------------- -------*
  // *===============================================---*
  {
    path: '/app/comment/comment-manage',
    name: 'apps-comment-manage',
    component: () => import('@/views/apps/contents/comment-manage/CommentManage.vue'),
    meta: {
      roleLevel: 7,
    },
  },

  // *===============================================---*
  // *--------- 약관관리 ---- ----------------- -------*
  // *===============================================---*
  {
    path: '/app/others/terms-manage',
    name: 'apps-terms-manage',
    component: () => import('@/views/apps/others/terms-manage/TermsManage.vue'),
    meta: {
      roleLevel: 7,
    },
  },

  // *===============================================---*
  // *--------- 탈회관리 ---- ----------------- -------*
  // *===============================================---*
  {
    path: '/app/members/withdraw-manage',
    name: 'apps-withdraw-manage',
    component: () => import('@/views/apps/members/withdraw-manage/WithdrawManage.vue'),
    meta: {
      roleLevel: 7,
    },
  },

  // *===============================================---*
  // *--------- 포인트 관리 ---- ----------------- -------*
  // *===============================================---*
  {
    path: '/app/points/point-history-manage',
    name: 'apps-point-history-manage',
    component: () => import('@/views/apps/points/point-history-manage/PointHistoryManage.vue'),
    meta: {
      roleLevel: 7,
    },
  },
  {
    path: '/app/points/point-system-manage',
    name: 'apps-point-system-manage',
    component: () => import('@/views/apps/points/point-system-manage/PointSystemManage.vue'),
    meta: {
      roleLevel: 7,
    },
  },
  {
    path: '/app/points/point-award-manage',
    name: 'apps-point-award-manage',
    component: () => import('@/views/apps/points/point-award-manage/PointAwardManage.vue'),
    meta: {
      roleLevel: 7,
    },
  },
  {
    path: '/app/points/point-gift-manage',
    name: 'apps-point-gift-manage',
    component: () => import('@/views/apps/points/point-gift-manage/PointGiftManage.vue'),
    meta: {
      roleLevel: 7,
    },
  },
  {
    path: '/app/points/gift-order-manage',
    name: 'apps-gift-order-manage',
    component: () => import('@/views/apps/points/gift-order-manage/GiftOrderManage.vue'),
    meta: {
      roleLevel: 7,
    },
  },
  // *===============================================---*
  // *--------- 통계 ---- ----------------- -------*
  // *===============================================---*
  {
    path: '/app/statistics/onoff',
    name: 'apps-statistics-onoff',
    component: () => import('@/views/apps/statistics/onoff/OnOffStatistics.vue'),
    meta: {
      roleLevel: 7,
    },
  },
  {
    path: '/app/statistics/moa-call',
    name: 'apps-statistics-moa-call',
    component: () => import('@/views/apps/statistics/moa-call/MoaCallStatistics.vue'),
    meta: {
      roleLevel: 7,
    },
  },
  {
    path: '/app/statistics/medical-trend',
    name: 'apps-statistics-medical-trend',
    component: () => import('@/views/apps/statistics/medicaltrend/MedicalTrendStatistics.vue'),
    meta: {
      roleLevel: 7,
    },
  },
  {
    path: '/app/statistics/drug-info',
    name: 'apps-statistics-drug-info',
    component: () => import('@/views/apps/statistics/druginfo/DrugInfoStatistics.vue'),
    meta: {
      roleLevel: 7,
    },
  },
  {
    path: '/app/statistics/live-di',
    name: 'apps-statistics-live-di',
    component: () => import('@/views/apps/statistics/livedi/LiveDiStatistics.vue'),
    meta: {
      roleLevel: 7,
    },
  },
  {
    path: '/app/statistics/drug-landing',
    name: 'apps-statistics-drug-landing',
    component: () => import('@/views/apps/statistics/druglanding/DrugLandingStatistics.vue'),
    meta: {
      roleLevel: 7,
    },
  },
  {
    path: '/app/statistics/webseminar',
    name: 'apps-statistics-webseminar',
    component: () => import('@/views/apps/statistics/webseminar/WebSeminarStatistics.vue'),
    meta: {
      roleLevel: 7,
    },
  },
  {
    path: '/app/statistics/event',
    name: 'apps-statistics-event',
    component: () => import('@/views/apps/statistics/event/EventStatistics.vue'),
    meta: {
      roleLevel: 7,
    },
  },
  {
    path: '/app/statistics/survey',
    name: 'apps-statistics-survey',
    component: () => import('@/views/apps/statistics/survey/SurveyStatistics.vue'),
    meta: {
      roleLevel: 7,
    },
  },
  {
    path: '/app/statistics/weekly-choice',
    name: 'apps-statistics-weekly-choice',
    component: () => import('@/views/apps/statistics/weekly-choice/WeeklyChoiceStatistics.vue'),
    meta: {
      roleLevel: 7,
    },
  },
  {
    path: '/app/statistics/sign-draw',
    name: 'apps-statistics-sign-draw',
    component: () => import('@/views/apps/statistics/sign-draw/SignDraw.vue'),
    meta: {
      roleLevel: 7,
    },
  },
  {
    path: '/app/statistics/entry',
    name: 'apps-statistics-entry',
    component: () => import('@/views/apps/statistics/entry/EntryStatistics.vue'),
    meta: {
      roleLevel: 7,
    },
  },
  {
    path: '/app/statistics/menu-entry',
    name: 'apps-statistics-menu-entry',
    component: () => import('@/views/apps/statistics/menu-entry/MenuEntryStatistics.vue'),
    meta: {
      roleLevel: 7,
    },
  },
  {
    path: '/app/statistics/user-join',
    name: 'apps-statistics-user-join',
    component: () => import('@/views/apps/statistics/user-join/UserJoinStatistics.vue'),
    meta: {
      roleLevel: 7,
    },
  },
  {
    path: '/app/statistics/content-join',
    name: 'apps-statistics-content-join',
    component: () => import('@/views/apps/statistics/content-join/ContentJoinStatistics.vue'),
    meta: {
      roleLevel: 7,
    },
  },
  // *===============================================---*
  // *--------- 신청 관리 ---- ----------------- -------*
  // *===============================================---*
  // 방문신청 요청 관리
  {
    path: '/app/asks/visit-ask-manage',
    name: 'apps-visit-ask-manage',
    component: () => import('@/views/apps/asks/visit-ask-manage/VisitAskManage.vue'),
    meta: {
      roleLevel: 7,
    },
  },
  // 샘플신청 관리
  {
    path: '/app/asks/sample-ask-manage',
    name: 'apps-sample-ask-manage',
    component: () => import('@/views/apps/asks/sample-ask-manage/SampleAskManage.vue'),
    meta: {
      roleLevel: 7,
    },
  },
  // 제약사 문의 관리
  {
    path: '/app/asks/emaile-ask-manage',
    name: 'apps-email-ask-manage',
    component: () => import('@/views/apps/asks/email-ask-manage/EmailAskManage.vue'),
    meta: {
      roleLevel: 7,
    },
  },

  // *===============================================---*
  // *-------------- 기타 ---- ----------------- -------*
  // *===============================================---*
  // 관리자 권한 관리
  {
    path: '/apps/others/manager-auth-manage',
    name: 'apps-manager-auth-manage',
    component: () => import('@/views/apps/others/manager-auth-manage/ManagerAuthManage.vue'),
    meta: {
      roleLevel: 8,
      privateInternal: true,
    },
  },
]
