import { render, staticRenderFns } from "./DrugLandingStatistics.vue?vue&type=template&id=4bd87789&"
import script from "./DrugLandingStatistics.vue?vue&type=script&lang=js&"
export * from "./DrugLandingStatistics.vue?vue&type=script&lang=js&"
import style0 from "./DrugLandingStatistics.vue?vue&type=style&index=0&id=4bd87789&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Jenkins_Home\\workspace\\liveinfo3-front-admin-dev\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4bd87789')) {
      api.createRecord('4bd87789', component.options)
    } else {
      api.reload('4bd87789', component.options)
    }
    module.hot.accept("./DrugLandingStatistics.vue?vue&type=template&id=4bd87789&", function () {
      api.rerender('4bd87789', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/apps/statistics/druglanding/DrugLandingStatistics.vue"
export default component.exports